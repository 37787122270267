import summer23 from '../../assets/images/df-magazine-summer-2023.jpeg';
import spring23 from '../../assets/images/df-magazine-spring-23.jpeg';
import winter23 from '../../assets/images/df-magazine-winter-2023.jpeg';
import fall23 from '../../assets/images/df-magazine-fall-2023.png';

export const thumbnails = [
  {
    id: 'fall23',
    src: fall23,
    title: 'Fall 2023',
    alt: 'fall23',
    iframe_url:
      'https://e.issuu.com/embed.html?d=23fall_dflm-web-linked&u=rowlandpublishing',
  },
  {
    id: 'summer23',
    src: summer23,
    title: 'Summer 2023',
    alt: 'summer23',
    iframe_url:
      'https://e.issuu.com/embed.html?d=23summer_dflm-web-linked&u=rowlandpublishing',
  },
  {
    id: 'spring23',
    src: spring23,
    title: 'Spring 2023',
    alt: 'spring23',
    iframe_url:
      'https://e.issuu.com/embed.html?d=23spring_dflm-web-linked&showOtherPublicationsAsSuggestions=true&u=rowlandpublishing',
  },
  {
    id: 'winter23',
    src: winter23,
    title: 'Winter 2023',
    alt: 'winter23',
    iframe_url:
      'https://e.issuu.com/embed.html?d=23winter_dflm_web-digitaledition&showOtherPublicationsAsSuggestions=true&u=rowlandpublishing',
  },
];
