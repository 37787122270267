import React, { useRef } from 'react';
import Slider from 'react-slick';
import { decode } from 'html-decoder';
import useSlides from '../../hooks/useSlides';
import SliderArrow from '../SliderArrow';
import useWindowSize from '../../hooks/useWindowSize';
import RealEstateCard from './RealEstateCard';

export default function StaticRealEstatePropertySlider({ title, listings }) {
  const sliderRef = useRef(null);

  const { width: windowWidth } = useWindowSize();
  const isDesktop = windowWidth > 768;

  const slideCount = useSlides({
    sliderRef,
    cardWidth: 293,
  });

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slideCount,
    // slidesToScroll: slideCount,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <SliderArrow direction="prev" />,
    nextArrow: <SliderArrow direction="next" />,
  };

  const parsedTitle = title ? decode(title.split('-').join(' ')) : '';

  const dataEmpty = listings.length === 0;

  // Desktop
  if (isDesktop) {
    return (
      <section className="slider-container real-estate" ref={sliderRef}>
        <header className="slider-header">
          <div className="title">{title && <h2>{parsedTitle}</h2>}</div>
        </header>

        {dataEmpty ? (
          <div className="slider-fallback">
            <p>No {decode(title.replace(/-/g, ' '))} Available</p>
          </div>
        ) : (
          <Slider {...settings}>
            {listings.map((mlsListing, ind) => (
              <RealEstateCard key={ind} data={mlsListing} />
            ))}
          </Slider>
        )}
      </section>
    );
  }

  // Mobile
  return (
    <section className="card-section-container" ref={sliderRef}>
      <aside className="card-section-header">
        <div className="header-title-container">
          <h2 className="header-title">{parsedTitle}</h2>
        </div>
      </aside>

      <div className="cards-wrapper" style={{ overflow: 'visible' }}>
        <div className="cards-container list">
          {listings.map((mlsListing, ind) => (
            <RealEstateCard key={ind} data={mlsListing} />
          ))}
          <span className="end-slider-padding" />
        </div>
      </div>
    </section>
  );
}
