import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactSVG from 'react-svg';
import { useInView } from 'react-intersection-observer';
import chevronSmall from 'chevron-small.svg';
import Slider from 'react-slick';
import { decode } from 'html-decoder';
import { ApolloProvider, useQuery } from 'react-apollo';
import useSlides from '../../hooks/useSlides';
import SliderArrow from '../SliderArrow';
import { REAL_ESTATE_LISTINGS_CARD } from '../../constants/queries';
import { client } from '../client';
import useWindowSize from '../../hooks/useWindowSize';
import RealEstateCard from './RealEstateCard';

const MAX_SLIDER_PAGE = 5;

function RealEstatePropertySlider({ title, link, filters = {} }) {
  const sliderRef = useRef(null);

  const { width: windowWidth } = useWindowSize();
  const isDesktop = windowWidth > 768;

  const [listings, setListings] = useState([]);

  const slideCount = useSlides({
    sliderRef,
    cardWidth: 293,
  });

  const [paddingRef, inView] = useInView({
    threshold: 0,
    rootMargin: '200px',
  });

  const { data, loading, error, fetchMore } = useQuery(
    REAL_ESTATE_LISTINGS_CARD,
    {
      variables: { per: isDesktop ? String(slideCount * 2) : '4', ...filters },
    }
  );

  useEffect(() => {
    if (data) {
      setListings(data.mls_listings.collection);
    }
  }, [data]);

  const handleFetchMore = useCallback(() => {
    if (data) {
      const { current_page } = data.mls_listings;
      fetchMore({
        variables: {
          page: String(Math.min(+current_page + 1, MAX_SLIDER_PAGE)),
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          const newResults = {
            mls_listings: {
              ...fetchMoreResult.mls_listings,
              collection: [
                ...prev.mls_listings.collection,
                ...fetchMoreResult.mls_listings.collection,
              ],
            },
          };
          return newResults;
        },
      });
    }
  }, [data, fetchMore]);

  // Handle Mobile Refetch
  useEffect(() => {
    if (data) {
      const { current_page } = data.mls_listings;
      if (inView && current_page < MAX_SLIDER_PAGE) {
        handleFetchMore();
      }
    }
  }, [data, handleFetchMore, inView]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slideCount,
    // slidesToScroll: slideCount,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <SliderArrow direction="prev" />,
    nextArrow: <SliderArrow direction="next" />,
    afterChange: newIndex => {
      if (data) {
        const { current_page, per_page } = data.mls_listings;
        if (
          slideCount + newIndex === per_page * current_page - 1 &&
          current_page < MAX_SLIDER_PAGE
        ) {
          handleFetchMore();
        }
      }
    },
  };

  if (error) console.error(error);

  const parsedTitle = title ? decode(title.split('-').join(' ')) : '';

  const dataEmpty = listings.length === 0;

  // Desktop
  if (isDesktop) {
    return (
      <section className="slider-container real-estate" ref={sliderRef}>
        <header className="slider-header">
          <div className="title">
            {title && (
              <h2>
                <a
                  className="secondary-text"
                  href={link}
                  title={`View All ${parsedTitle}`}
                  aria-label={`View All ${parsedTitle}`}
                >
                  {parsedTitle}
                </a>
              </h2>
            )}
          </div>
          <div className="view-all">
            {dataEmpty && loading ? (
              <div className="loading-text sub-title" />
            ) : (
              <Fragment>
                <a
                  className="secondary-text"
                  href={link}
                  aria-label={`View All ${parsedTitle}`}
                >
                  View All
                </a>
                <ReactSVG src={chevronSmall} alt="" />
              </Fragment>
            )}
          </div>
        </header>

        {dataEmpty && !loading ? (
          <div className="slider-fallback">
            <p>No {decode(title.replace(/-/g, ' '))} Available</p>
          </div>
        ) : (
          <Slider {...settings}>
            {listings.map((mlsListing, ind) => (
              <RealEstateCard key={ind} data={mlsListing} />
            ))}
          </Slider>
        )}
      </section>
    );
  }

  // Mobile
  return (
    <section className="card-section-container" ref={sliderRef}>
      <aside className="card-section-header">
        <div className="header-title-container">
          <h2 className="header-title">{parsedTitle}</h2>
        </div>
      </aside>

      <div className="cards-wrapper" style={{ overflow: 'visible' }}>
        <div className="cards-container">
          {listings.map((mlsListing, ind) => (
            <RealEstateCard key={ind} data={mlsListing} />
          ))}
          {!loading && listings.length && (
            <div className="slider-end-padding" ref={paddingRef} />
          )}
        </div>
      </div>
    </section>
  );
}

export default function RealEstatePropertySliderWrapper({ ...props }) {
  return (
    <ApolloProvider client={client}>
      <RealEstatePropertySlider {...props} />
    </ApolloProvider>
  );
}
