import React, { useState } from 'react';
import useWindowSize from '../hooks/useWindowSize';
import { thumbnails } from '../constants/magazines';

function MagazineThumbnails({ id }) {
  const [showThumbs, setShowThumbs] = useState(false);

  const { width } = useWindowSize();

  const defaultIframeSrc = thumbnails.find((thumbnail) => thumbnail.id == id)?.iframe_url;
  const [iframeSrc, setIframeSrc] = useState(defaultIframeSrc);

  const isTablet = width <= 1024;

  const toggleThumbs = () => {
    setShowThumbs(!showThumbs);
  };

  const visibleThumbnails = isTablet
    ? thumbnails.slice(0, 3)
    : thumbnails.slice(0, 4);

  const hiddenThumbnails = isTablet ? thumbnails.slice(3) : thumbnails.slice(4);

  const handleClick = thumbnail => {
    setIframeSrc(thumbnail.iframe_url);
  };

  const showViewMore =
    (thumbnails.length > 3 && isTablet) || (thumbnails.length > 4 && !isTablet);

  const lessThanFourThumbnails = visibleThumbnails.length < 4;

  return (
    <div>
      <div
        style={{
          position: 'relative',
          paddingTop: 'max(60%,326px)',
          height: '0',
          width: '100%',
        }}
      >
        <iframe
          id="magazine-iframe"
          allow="clipboard-write"
          sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms"
          allowFullScreen="true"
          style={{
            position: 'absolute',
            border: 'none',
            width: '100%',
            height: '100%',
            left: '0',
            right: '0',
            top: '0',
            bottom: '0',
          }}
          // src="https://e.issuu.com/embed.html?d=23spring_dflm-web-linked&showOtherPublicationsAsSuggestions=true&u=rowlandpublishing"
          src={iframeSrc}
          title="magazine-iframe"
        />
      </div>
      <p className="magazine-text">
        View our latest issues here or visit our full site for more information
        at{' '}
        <a
          aria-label="Destin Florida Magazine"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.destinfloridamagazine.com/"
        >
          DESTINFLORIDAMAGAZINE.COM
        </a>
      </p>

      <p className="magazine-text">
        For marketing opportunities please email us at{' '}
        <a href="mailto:admin@destinflorida.com">admin@destinflorida.com</a>
      </p>
      <h1 className="magazine-header-thumbnails">Latest Issues</h1>
      <div
        className={
          lessThanFourThumbnails
            ? 'thumb-container-flex'
            : 'magazine-thumb-container'
        }
        id="magazine-thumb-container"
      >
        {visibleThumbnails.map((thumbnail, index) => {
          return (
            <button type="button" onClick={() => handleClick(thumbnail)}>
              <div key={index} className="magazine-thumb-wrapper">
                <img src={thumbnail.src} alt={thumbnail.alt} />
                <p className="magazine-thumb-text">{thumbnail.title}</p>
              </div>
            </button>
          );
        })}
        {showThumbs &&
          hiddenThumbnails.map((thumbnail, index) => {
            return (
              <button type="button" onClick={() => handleClick(thumbnail)}>
                <div key={index} className="magazine-thumb-wrapper">
                  <img src={thumbnail.src} alt={thumbnail.alt} />
                  <p className="magazine-thumb-text">{thumbnail.title}</p>
                </div>
              </button>
            );
          })}
      </div>
      {showViewMore && (
        <button type="button" className="btn btn--dark" onClick={toggleThumbs}>
          {!showThumbs ? 'View More' : 'View Less'}
        </button>
      )}
    </div>
  );
}

export default MagazineThumbnails;
