import React from 'react';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import sliderArrow from 'slider-arrow.svg';

export default function SliderArrow({
  direction,
  className,
  onClick,
  type,
  disabled,
}) {
  let translateX = '-10px';
  if (direction === 'prev' && type !== 'communities') {
    translateX = '10px';
  } else if (type === 'communities' || type === 'categories') {
    translateX = '0px';
  }

  return (
    <button
      aria-label="Slider Arrow"
      name="Slider Arrow"
      type="button"
      className={`${className} slider-arrow ${direction} ${className} ${
        disabled ? 'custom-disabled' : ''
      }`}
      onClick={!disabled ? onClick : () => {}}
      style={{
        transform: `translate(${translateX}, -50%) ${
          type === 'categories' ? 'scale(0.7)' : ''
        }`,
        // If the type is one of the items in the array (square cards), the top is 50%
        // eslint-disable-next-line
        top: ['events', 'instagram', 'communities', 'categories'].includes(type)
          ? '50%'
          : type === 'guides'
          ? '32%'
          : '39%',
      }}
    >
      <ReactSVG src={sliderArrow} />
    </button>
  );
}

SliderArrow.propTypes = {
  direction: PropTypes.oneOf(['next', 'prev']).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};
