export default function formatCurrency(num, options) {
  const currencyString = num.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    ...options,
  });

  return currencyString.substr(0, currencyString.length - 3);
}

// Solution: https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900
export function shortCurrencyFormatter(num, digits = 0) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(i => {
      return num >= i.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
}
