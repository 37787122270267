import React, { useEffect, useRef, useState } from 'react';
import ReactSVG from 'react-svg';
import axios from 'axios';
import heart_path from 'heart_path.svg';
import { ApolloProvider, useQuery } from 'react-apollo';
import { toast } from 'react-toastify';
import { disableBodyScroll } from 'body-scroll-lock';
import formatCurrency from '../../utils/formatCurrency';
import mls_categories from '../../constants/mls_categories.json';
import mls_statuses from '../../constants/mls_statuses.json';
import { userQuery } from '../../constants/queries';
import FavoriteSuccessBanner from '../CustomToast/FavoriteSuccessBanner';
import { handleHeartAnimation } from '../../utils/handleHeartAnimation';
import { client, clientNoVarnish } from '../client';

function RealEstateCard({ data, onClick, ...props }) {
  const heartRef = useRef(null);
  const cardRef = useRef(null);

  // Check for 'Commercial for Lease' or 'Rental'
  const isRental = data.property_type === 'G' || data.property_type === 'F';

  const { data: userData } = useQuery(userQuery, {
    fetchPolicy: 'no-cache',
    client: clientNoVarnish,
  });

  const [favorited, setFavorited] = useState(data.favorited);
  const [favoriteRoute, setFavoriteRoute] = useState(null);

  useEffect(() => {
    setFavoriteRoute(
      window.Routes.favorite_mls_listing_path({ ...data, id: data.id })
    );
  }, [data]);

  const handleFavorite = () => {
    const tokenElement = document.querySelector('[name=csrf-token]');
    if (userData && userData.user && userData.user.id) {
      axios
        .patch(favoriteRoute, null, {
          headers: {
            'X-CSRF-Token': tokenElement ? tokenElement.content : '',
            Accept: 'application/json',
          },
        })
        .then(res => {
          handleHeartAnimation({ ref: heartRef, isFavorited: favorited });
          const { pathname } = window.location;
          if (res.status === 200) {
            if (
              pathname.includes('/account') ||
              pathname.includes('/favorites')
            ) {
              cardRef.current.style.opacity = 0;
              setTimeout(() => {
                cardRef.current.parentElement.remove();
              }, 300);
            }
            toast.dismiss();
            if (!favorited)
              toast(<FavoriteSuccessBanner favorited type={data} />);
            else toast(<FavoriteSuccessBanner type={data} />);
            setFavorited(!favorited);
          }
        })
        .catch(err => {
          console.error(err);
          if (err.response.status === 401) {
            window.location.pathname = window.Routes.login_path();
          }
        });
    } else {
      const modal = document.querySelector('#popup-modal');
      modal.classList.remove('fadeOut');
      modal.classList.remove('hidden');
      modal.classList.add('fadeIn');
      disableBodyScroll(modal);
    }
  };

  const subType = mls_categories.find(c => c.value === data.sub_type);

  return (
    <div ref={cardRef} className="rental-card real-estate" {...props}>
      <section className="rental-upper-wrapper">
        <button
          aria-label="Toggle Favorite"
          name="Toggle Favorite"
          ref={heartRef}
          type="button"
          className={`card-heart rental-favorite ${
            data.favorited ? 'favorited' : ''
          }`}
          onClick={handleFavorite}
        >
          <ReactSVG src={heart_path} className="heart-icon" />
        </button>
        <a
          className="rental-card-slider-container"
          aria-label={`${data.address} Show Page`}
          href={`/for-sale/${data?.address_show_url}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => {
            if (onClick) {
              e.preventDefault();
              onClick();
            }
          }}
        >
          <section className="rental-image-outer">
            <img
              data-src={data.feature_image_url}
              alt={`${data.address}`}
              title={`${data.address}`}
              className="rental-image lazyload"
            />
            <div className="rental-slider-overlay" />
          </section>
        </a>
      </section>
      <a
        className="rental-lower-wrapper"
        aria-label={`${data.address} Show Page`}
        href={`/for-sale/${data?.address_show_url}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={e => {
          if (onClick) {
            e.preventDefault();
            onClick();
          }
        }}
      >
        <div className="rental-lower-header">
          {data.sub_type && (
            <p className="rental-type">
              {subType ? subType.short_label : data.sub_type}
            </p>
          )}
        </div>

        <h3 className="rental-title">
          {formatCurrency(parseInt(data.sold_price ?? data.list_price))}
        </h3>

        <p>{data.address}</p>
        <p className="mls-status-text">
          {data.listing_status && (
            <span
              className={`mls-status ${data.listing_status
                .toLowerCase()
                .replace(/\s/g, '-')}`}
            />
          )}
          <span>
            {data.listing_status &&
              mls_statuses[
                isRental ? `${data.listing_status}-Rental` : data.listing_status
              ]}
            {(data.total_bedrooms || data.total_baths || data.apx_sqft) &&
              ` • ${data.total_bedrooms || 0} BR ${data.total_baths || 0} BA ${
                data && data.apx_sqft
                  ? ` • ${parseInt(data.apx_sqft).toLocaleString()} SF`
                  : ''
              }`}
          </span>
        </p>
      </a>
    </div>
  );
}

export default function RealEstateCardWrapper({ ...props }) {
  return (
    <ApolloProvider client={client}>
      <RealEstateCard {...props} />
    </ApolloProvider>
  );
}
