import React, { useState, Fragment, useMemo } from 'react';
import { ApolloProvider, useLazyQuery } from 'react-apollo';
import { DebounceInput } from 'react-debounce-input';
import ReactSVG from 'react-svg';
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
  ComboboxOptionText,
} from '@reach/combobox';
import '@reach/combobox/styles.css';
import home from 'home.svg';
import marker from 'map_marker.svg';
import RealtyOneGoldLogo from 'images/realty-one-group-gold.png';
import { client } from '../client';
import ErrorBoundary from '../ErrorBoundary';
import {
  REAL_ESTATE_SEARCH,
  RANDOM_REAL_ESTATE_SEARCH,
} from '../../constants/queries';

function RealEstateSearch({ showLogo = true }) {
  const [getRandomListings, { data: randomListings }] = useLazyQuery(
    RANDOM_REAL_ESTATE_SEARCH
  );
  const [searchListings, { data, loading }] = useLazyQuery(REAL_ESTATE_SEARCH, {
    fetchPolicy: 'cache-and-network',
  });

  const [value, setValue] = useState('');

  const handleSelect = item => {
    Array.from(document.querySelectorAll('[data-reach-combobox-option]'))
      .find(node => node.innerText === item)
      .click();
  };

  const handleSubmit = e => {
    e.preventDefault();
    window.location.href = window.Routes.for_sale_path({
      address_contains: value,
    });
  };

  const resultsEmpty = useMemo(() => {
    if (data && data.mls_search) {
      const dataOptions = [
        ...data.mls_search.mls_listings,
        ...data.mls_search.areas,
        ...data.mls_search.zipcodes,
      ];

      return dataOptions.length === 0 && value && !loading;
    }

    return !!(value && !loading);
  }, [data, loading, value]);

  return (
    <form
      className="communities-search-container real-estate"
      onSubmit={handleSubmit}
    >
      <div className="input-outer-wrapper">
        <section className="input-wrapper">
          <Combobox openOnFocus onSelect={handleSelect}>
            <DebounceInput
              element={ComboboxInput}
              debounceTimeout={300}
              placeholder="Enter an address, area, or ZIP code"
              value={value}
              onChange={e => {
                searchListings({ variables: { title_cont: e.target.value } });
                setValue(e.target.value);
              }}
              onFocus={() => {
                if (!randomListings)
                  getRandomListings({ variables: { per: '4' } });
              }}
            />
            <ComboboxPopover className="real-estate-combobox">
              <ComboboxList>
                {!value ? (
                  <Fragment>
                    <h3>Find homes for sale by area</h3>
                    <ComboboxOption
                      as="a"
                      href={window.Routes.for_sale_path({ areas: 'destin' })}
                      value="Destin"
                    >
                      <ReactSVG
                        svgStyle={{ height: '20px', width: '14px' }}
                        src={marker}
                      />{' '}
                      <ComboboxOptionText />
                    </ComboboxOption>
                    <ComboboxOption
                      as="a"
                      href={window.Routes.for_sale_path({
                        areas: '30a_west,30a_east',
                      })}
                      value="30A"
                    >
                      <ReactSVG
                        svgStyle={{ height: '20px', width: '14px' }}
                        src={marker}
                      />{' '}
                      <ComboboxOptionText />
                    </ComboboxOption>
                    <ComboboxOption
                      as="a"
                      href={window.Routes.for_sale_path({
                        areas: 'fort_walton_beach',
                      })}
                      value="Fort Walton Beach"
                    >
                      <ReactSVG
                        svgStyle={{ height: '20px', width: '14px' }}
                        src={marker}
                      />{' '}
                      <ComboboxOptionText />
                    </ComboboxOption>
                    <ComboboxOption
                      as="a"
                      href={window.Routes.for_sale_path({
                        areas: 'miramar_sandestin_resort',
                      })}
                      value="Miramar Beach"
                    >
                      <ReactSVG
                        svgStyle={{ height: '20px', width: '14px' }}
                        src={marker}
                      />{' '}
                      <ComboboxOptionText />
                    </ComboboxOption>
                    {randomListings && randomListings.mls_listings && (
                      <Fragment>
                        <h3>Find homes for sale by address</h3>
                        {randomListings.mls_listings.collection.map(l => (
                          <ComboboxOption
                            as="a"
                            href={window.Routes.for_sale_path({
                              listing_url: l?.address_show_url,
                            })}
                            key={l.id}
                            value={l.address}
                          >
                            <ReactSVG
                              svgStyle={{ height: '16px', width: '16px' }}
                              src={home}
                            />{' '}
                            <span>{l.address}</span>
                          </ComboboxOption>
                        ))}
                      </Fragment>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {/* Areas */}
                    {data &&
                      data.mls_search &&
                      data.mls_search.areas.length !== 0 && (
                        <Fragment>
                          <h3>Find homes for sale by area</h3>
                          {data.mls_search.areas.splice(0, 4).map(a => (
                            <ComboboxOption
                              as="a"
                              href={window.Routes.for_sale_path({
                                areas: a.slug,
                              })}
                              key={a.id}
                              value={a.name}
                            >
                              <ReactSVG
                                svgStyle={{ height: '20px', width: '14px' }}
                                src={marker}
                              />{' '}
                              <span>{a.name}</span>
                            </ComboboxOption>
                          ))}
                        </Fragment>
                      )}

                    {/* Zipcode */}
                    {data &&
                      data.mls_search &&
                      data.mls_search.zipcodes.length !== 0 && (
                        <Fragment>
                          <h3>Find homes for sale by ZIP code</h3>
                          {data.mls_search.zipcodes
                            .splice(0, 4)
                            .map((z, ind) => (
                              <ComboboxOption
                                as="a"
                                href={window.Routes.for_sale_path({
                                  zipcode: z.postal_code,
                                })}
                                key={ind}
                                value={`${z.postal_code}, ${z.city}, ${z.state}`}
                              >
                                <ReactSVG
                                  svgStyle={{ height: '20px', width: '14px' }}
                                  src={marker}
                                />{' '}
                                <span>
                                  {z.postal_code}, {z.city}, {z.state}
                                </span>
                              </ComboboxOption>
                            ))}
                        </Fragment>
                      )}

                    {/* Address */}
                    {data &&
                      data.mls_search &&
                      data.mls_search.mls_listings.length !== 0 && (
                        <Fragment>
                          <h3>Find homes for sale by address</h3>
                          {data.mls_search.mls_listings.splice(0, 8).map(l => (
                            <ComboboxOption
                              as="a"
                              href={window.Routes.for_sale_path({
                                listing_url: l?.address_show_url,
                              })}
                              key={l.id}
                              value={l.address}
                            >
                              <ReactSVG
                                svgStyle={{ height: '20px', width: '14px' }}
                                src={marker}
                              />{' '}
                              <span>{l.address}</span>
                            </ComboboxOption>
                          ))}
                        </Fragment>
                      )}

                    {/* Empty Results */}
                    {resultsEmpty && (
                      <p className="empty">
                        Oops! We couldn’t find anything. Please try again.
                      </p>
                    )}

                    {/* Loading */}
                    {loading && <p className="empty">Loading...</p>}
                  </Fragment>
                )}
              </ComboboxList>
            </ComboboxPopover>
          </Combobox>
        </section>
        <button
          type="button"
          aria-label="Search Real Estate"
          onClick={handleSubmit}
          className="btn btn--blue-grad"
        >
          Search
        </button>
      </div>
      {showLogo && (
        <img
          src={RealtyOneGoldLogo}
          alt="Realty One Group"
          className="mls-form-image lazyload"
          style={{ marginTop: '5px' }}
        />
      )}
    </form>
  );
}

export default function RealEstateSearchWrapped({ ...props }) {
  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <RealEstateSearch {...props} />
      </ApolloProvider>
    </ErrorBoundary>
  );
}
