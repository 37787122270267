import React from 'react';
import { ApolloProvider, useQuery } from 'react-apollo';
import { REAL_ESTATE_TYPE_COUNT } from '../../constants/queries';
import { client } from '../client';

function RealEstateTypeCard({
  title,
  propertyType,
  subType,
  href,
  largeImageSrc,
  smallImageSrc,
  fallbackFormat,
  areaSlug,
}) {
  const { data } = useQuery(REAL_ESTATE_TYPE_COUNT, {
    variables: {
      property_type_eq: propertyType,
      sub_type_eq: subType,
      area_slug: areaSlug,
    },
  });

  return (
    <a href={href} className="real-estate-type-card">
      <div className="real-estate-type-card-image-wrapper">
        <picture className="real-estate-type-card-image background-picture">
          <source
            data-srcset={`${largeImageSrc} 1280w, ${smallImageSrc} 400w" type="image/${fallbackFormat}`}
          />
          <img dataSrc={largeImageSrc} className="lazyload" alt="" />
        </picture>
      </div>
      <p className="real-estate-type-card-title">{title}</p>
      <p className="real-estate-type-card-count">
        View {data ? data.mls_listings.total_count : ''}{' '}
        {title.replace(/ for Sale| for Rent/, '')}
      </p>
    </a>
  );
}

export default function RealEstateTypeCardWrapper({ ...props }) {
  return (
    <ApolloProvider client={client}>
      <RealEstateTypeCard {...props} />
    </ApolloProvider>
  );
}
