export const handleHeartAnimation = ({ ref, isFavorited }) => {
  if (!isFavorited) {
    ref.current.classList.add('favoriting');
    setTimeout(() => {
      ref.current.classList.remove('favoriting');
      ref.current.classList.add('favorited');
    }, 260);
  } else {
    ref.current.classList.add('unfavoriting');
    setTimeout(() => {
      ref.current.classList.remove('unfavoriting');
      ref.current.classList.remove('favorited');
    }, 260);
  }
};
