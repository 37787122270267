/* eslint-disable import/order */
import React, { useRef } from 'react';
import Slider from 'react-slick';
import useWindowSize from '../../hooks/useWindowSize';
import SliderArrow from '../SliderArrow';
import RealEstateTypeCard from './RealEstateTypeCard';
import useSlides from '../../hooks/useSlides';

// Images
import homesSaleLarge from 'large/real_estate_homes_sale.jpeg';
import condosSaleLarge from 'large/real_estate_condos_sale.jpeg';
import townhomesSaleLarge from 'large/real_estate_townhomes_sale.jpeg';
import landSaleLarge from 'large/real_estate_land_sale.jpeg';
import condosRentLarge from 'large/real_estate_condos_rent.jpeg';
import homesRentLarge from 'large/real_estate_homes_rent.jpeg';
import townhomesRentLarge from 'large/real_estate_townhomes_rent.jpeg';
import apartmentsRentLarge from 'large/real_estate_apartments_rent.jpeg';

import homesSaleSmall from 'small/real_estate_homes_sale-small.jpeg';
import condosSaleSmall from 'small/real_estate_condos_sale-small.jpeg';
import townhomesSaleSmall from 'small/real_estate_townhomes_sale-small.jpeg';
import landSaleSmall from 'small/real_estate_land_sale-small.jpeg';
import condosRentSmall from 'small/real_estate_condos_rent-small.jpeg';
import homesRentSmall from 'small/real_estate_homes_rent-small.jpeg';
import townhomesRentSmall from 'small/real_estate_townhomes_rent-small.jpeg';
import apartmentsRentSmall from 'small/real_estate_apartments_rent-small.jpeg';

// ?type=Residential&categories=Attached%20Single%20Unit
const typesList = [
  {
    title: 'Homes for Sale',
    propertyType: 'A',
    subType: 'Detached Single Family',
    href: '/for-sale?type=Residential&categories=Detached%20Single%20Family',
    largeImageSrc: homesSaleLarge,
    smallImageSrc: homesSaleSmall,
    fallbackFormat: 'jpeg',
  },
  {
    title: 'Condos for Sale',
    propertyType: 'A',
    subType: 'Condominium',
    href: '/for-sale?type=Residential&categories=Condominium',
    largeImageSrc: condosSaleLarge,
    smallImageSrc: condosSaleSmall,
    fallbackFormat: 'jpeg',
  },
  {
    title: 'Townhomes for Sale',
    propertyType: 'A',
    subType: 'Attached Single Unit',
    href: '/for-sale?type=Residential&categories=Attached%20Single%20Unit',
    largeImageSrc: townhomesSaleLarge,
    smallImageSrc: townhomesSaleSmall,
    fallbackFormat: 'jpeg',
  },
  {
    title: 'Vacant Land for Sale',
    propertyType: 'C',
    subType: 'Vacant Land',
    href: '/for-sale?type=Land&categories=Vacant%20Land',
    largeImageSrc: landSaleLarge,
    smallImageSrc: landSaleSmall,
    fallbackFormat: 'jpeg',
  },
  {
    title: 'Condos for Rent',
    propertyType: 'G',
    subType: 'Condominium',
    href: '/for-sale?type=Rental&categories=Condominium',
    largeImageSrc: condosRentLarge,
    smallImageSrc: condosRentSmall,
    fallbackFormat: 'jpeg',
  },
  {
    title: 'Homes for Rent',
    propertyType: 'G',
    subType: 'Single Family Residence',
    href: '/for-sale?type=Rental&categories=Single%20Family%20Residence',
    largeImageSrc: homesRentLarge,
    smallImageSrc: homesRentSmall,
    fallbackFormat: 'jpeg',
  },
  {
    title: 'Townhomes for Rent',
    propertyType: 'G',
    subType: 'Townhouse',
    href: '/for-sale?type=Rental&categories=Townhouse',
    largeImageSrc: townhomesRentLarge,
    smallImageSrc: townhomesRentSmall,
    fallbackFormat: 'jpeg',
  },
  {
    title: 'Apartment for Rent',
    propertyType: 'G',
    subType: 'Apartment',
    href: '/for-sale?type=Rental&categories=Apartment',
    largeImageSrc: apartmentsRentLarge,
    smallImageSrc: apartmentsRentSmall,
    fallbackFormat: 'jpeg',
  },
];

export default function TypePropertiesSlider({ areaSlug = '' }) {
  const sliderRef = useRef(null);

  const { width: windowWidth } = useWindowSize();
  const isMobile = windowWidth <= 768;

  const slideCount = useSlides({
    sliderRef,
    cardWidth: 220,
  });

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: true,
    prevArrow: <SliderArrow type="categories" direction="prev" />,
    nextArrow: <SliderArrow type="categories" direction="next" />,
    slidesToScroll: 1,
    slidesToShow: slideCount,
  };

  return (
    <section className="card-section-container" ref={sliderRef}>
      <aside className="card-section-header">
        <div className="header-title-container" style={{ margin: 'auto' }}>
          <h2 className="header-title">Types of Properties For Sale</h2>
        </div>
      </aside>

      <div className="cards-wrapper" style={{ overflow: 'visible' }}>
        {isMobile ? (
          <div className="cards-container no-grid">
            {typesList.map((t, ind) => (
              <RealEstateTypeCard
                key={ind}
                title={t.title}
                propertyType={t.propertyType}
                subType={t.subType}
                href={`${t.href}${areaSlug ? `&areas=${areaSlug}` : ''}`}
                largeImageSrc={t.largeImageSrc}
                smallImageSrc={t.smallImageSrc}
                fallbackFormat={t.fallbackFormat}
                areaSlug={areaSlug}
              />
            ))}
            <div className="slider-end-padding" />
          </div>
        ) : (
          <Slider {...settings}>
            {typesList.map((t, ind) => (
              <RealEstateTypeCard
                key={ind}
                title={t.title}
                propertyType={t.propertyType}
                subType={t.subType}
                href={`${t.href}${areaSlug ? `&areas=${areaSlug}` : ''}`}
                largeImageSrc={t.largeImageSrc}
                smallImageSrc={t.smallImageSrc}
                fallbackFormat={t.fallbackFormat}
                areaSlug={areaSlug}
              />
            ))}
          </Slider>
        )}
      </div>
    </section>
  );
}
